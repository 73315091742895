<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :width="width">
      <v-card>
        <v-card-title>{{title}}</v-card-title>
        <v-card-text>{{text}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmButtonClick" color="primary lighten-1">{{confirmButtonTxt}}</v-btn>
          <v-btn text @click="cancelButtonClick" color="red lighten-2">{{cancelButtonTxt}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dialog: false,
        width: '60%',
        title: 'Confirm Option',
        text: 'Confirmation text value',
        confirmButtonTxt: 'Confirm',
        cancelButtonTxt: 'Cancel',
      }
    },
    methods: {
      showDialog(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.text = message;
        this.width = options.width;
        this.confirmButtonTxt = options.confirmButtonTxt;
        this.cancelButtonTxt = options.cancelButtonTxt;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        })
      },
      confirmButtonClick() {
        this.resolve(true);
        this.dialog = false;
      },
      cancelButtonClick() {
        this.resolve(false);
        this.dialog = false;
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>