<template>
  <div>
    <span v-if="overriddenItems.length > 0">Overridden Documents</span>
    <v-simple-table v-if="overriddenItems.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Document Name</th>
            <th>Path</th>
            <th class="text-left">Publish Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in overriddenItems" :key="file.sysId">
            <td class="text-left">
              <span class="treeIcon" :class="parseFileType(file.type)" :title="file.path"></span>
              {{file.name}}
            </td>
            <td><span class="treeIcon folder" :title="file.path"></span></td>
            <td class="text-left">
              <span class="treeIcon" :class="parseFileType(file.publishType)"></span>
              {{file.publishType}}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import fileTypeParser from '../mixins/fileTypeParser.mixin';

export default {
  mixins: [fileTypeParser],
  props: {
    curriculumGuid: {
      type: String,
    },
    webconUser: {
      type: String,
    },
  },
  mounted() {
    this.$store.dispatch('getCurriculumHierarchy',
      this.$props.curriculumGuid,
      this.$props.webconUser);
  },
  computed: {
    overriddenItems() {
      return this.getOverriddenItems(this.$store.getters.Hierarchy, '');
    },
  },
  methods: {
    getOverriddenItems(items, path) {
      const overriddenItems = [];
      items.forEach(obj => {
        if (obj.type.toUpperCase() === 'FOLDER') {
          if (obj.children && obj.children.length > 0) {
            overriddenItems.push(this.getOverriddenItems(obj.children, `${path}/${obj.name}`));
          }
        }
        if (obj.publishType && obj.publishType !== '') {
          obj.path = path;
          overriddenItems.push(obj);
        }
      });
      return overriddenItems.flat();
    }
  },
}
</script>

<style lang="scss" scoped>

</style>