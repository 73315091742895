<template>
  <div>
    <v-card elevation="1"
      title="Curriculum Objects">
      <v-card-title>
        <v-row>
          <v-col cols="5">
            <span text @click="toggleView"><v-icon>{{toggleIcon}}</v-icon></span>
            <span @click="toggleView">Curriculum Objects</span>
          </v-col>
          <v-col cols="1" offset="4" v-if="!isCollapsed">
            <v-btn text @click="saveOrder" :disabled="!saveEnabled"><v-icon color="grey">mdi-content-save</v-icon>Save</v-btn>
          </v-col>
          <v-col cols="1" v-if="!isCollapsed">
            <v-btn text @click="showImport"><v-icon color="green">mdi-plus-circle-outline</v-icon>Import</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        v-if="!isCollapsed"
        :headers="tableHeaders"
        :items="objects"
        :show-expand="false"
        :show-select="false"
        :show-group-by="false"
        :disable-pagination="true"
        :hide-default-footer="true"
        dense
        loading="tableLoading">
        <template v-slot:progress>
          <v-progress-linear
            v-if="tableLoading"
            indeterminate
            rounded>
          </v-progress-linear>
        </template>
        <template v-slot:loading>
          Fetching Curriculum Objects...
        </template>
        <template v-slot:body="props">
          <draggable
            @end="objectMoved"
            v-model="objects"
            tag="tbody">
            <tr v-for="(item, rIdx) in props.items" :key="rIdx">
              <td>
                <v-icon>mdi-drag-horizontal</v-icon>
              </td>
              <td>
                <span class="treeIcon" :class="parseFileType(item.type)"></span>
                {{item.name}}
              </td>
              <td>
                {{item.type}}
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="showDialog"
      persistent
      width="60%">
      <v-card rounded shaped>
        <v-card-title>Import Objects</v-card-title>
        <v-card-text>
          <v-text-field label="Google Drive URL" v-model="importData.googleDriveUrl">
            <v-btn text slot="append" @click="fetchDriveData">
              <v-icon color="grey">mdi-cloud-download</v-icon>Go
            </v-btn>
          </v-text-field>
          <v-text-field
            v-if="importData.showTable"
            label="Filter"
            v-model="importData.filter">
            <v-btn text slot="append" @click="importData.filter = ''" v-if="importData.filter && importData.filter.length > 0">
              <v-icon color="red lighten-2">mdi-cancel</v-icon>
            </v-btn>
          </v-text-field>
          <v-container v-if="importData.showTable">
            <v-data-table
              :headers="importData.tableHeaders"
              :items="driveObjectsFiltered"
              v-model="importData.selectedObjects"
              :show-expand="false"
              :show-select="true"
              :show-group-by="false"
              :sort-by="['name']"
              :disable-pagination="true"
              :hide-default-footer="true"
              dense>
              <template v-slot:item.name="{ item }">
                <span class="treeIcon" :class="getIcon(item)"></span>
                {{item.name}}
              </template>
            </v-data-table>
          </v-container>
          <v-container v-if="importData.showError">
            <p>
              The URL you have provided is not accessible by our service account.  Please ensure that <b>{{serviceAccountEmail}}</b> has write access to the folder URL you are providing.
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary lighten-2" @click="importFiles" :disabled="!importData.showTable">Import</v-btn>
          <v-btn text color="red lighten-2" @click="cancelImport">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirm">
    </confirm-dialog>
  </div>
</template>

<script>
import fileTypeParser from '../mixins/fileTypeParser.mixin';
import hierarchyService from '../services/hierarchy.service';
import draggable from 'vuedraggable';
import collapsible from '../mixins/collapsible.mixin';
import confirmDialog from './ConfirmDialog.vue';

export default {
  mixins: [fileTypeParser, collapsible],
  components: {
    draggable,
    confirmDialog,
  },
  props: {
    curriculumGuid: {
      type: String,
    },
    hierarchyGuid: {
      type: String,
    },
    selectedNode: {
      type: Object,
    },
    webconUser: {
      type: String,
    },
  },
  data() {
    return {
      showDialog: false,
      tableLoading: false,
      saveEnabled: false,
      objects: [],
      importData: {
        googleDriveUrl: '',
        filter: '',
        showTable: false,
        showError: false,
        tableHeaders: [
          { text: 'File Name', align: 'start', sortable: true, value: 'name' },
          { text: 'Type', align: 'start', sortable: false, value: 'type' },
        ],
        driveObjects: [
        ],
        selectedObjects: [],
      },
      tableHeaders: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'File Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Object Type',
          align: 'start',
          sortable: false,
          value: 'type',
        }
      ],
    }
  },
  watch: {
    selectedNode(oldValue, newValue) {
      if (oldValue.id !== newValue.id) {
        this.tableLoading = true;
        this.fetchData();
      }
    }
  },
  mounted() {
    this.$store.dispatch('getCurriculumHierarchy',
      this.$props.curriculumGuid,
      this.$props.webconUser);
    this.fetchData();
  },
  computed: {
    serviceAccountEmail() {
      return process.env.VUE_APP_SERVICE_ACCOUNT;
    },
    driveObjectsFiltered() {
      return this.importData.driveObjects.filter(item => {
        return item.name.includes(this.importData.filter);
      })
    }
  },
  methods: {
    async fetchData() {
      this.objects = JSON.parse(JSON.stringify(
        this.$props.selectedNode.children.filter((item) => {
          return item.type.toLowerCase() !== 'folder';
        })
      ));
      this.tableLoading = false;
      this.saveEnabled = false;
    },
    async saveOrder() {
      const updateModel = [];
      for (let index = 0; index < this.objects.length; index++) {
        this.objects[index].sortOrder = index;
        updateModel.push({
          objectId: this.objects[index].id,
          sortOrder: this.objects[index].sortOrder,
        })
      }
      
      await hierarchyService.saveHierarchyObjectOrder(this.$props.curriculumGuid, this.$props.selectedNode.guid, updateModel, this.$props.webconUser)
        .then(() => {
          this.$store.dispatch('reloadHierarchy');
          this.$store.dispatch('getCurriculumHierarchy',
            this.$store.getters.CurriculumGuid,
            this.$props.webconUser);
        });
      this.saveEnabled = false;
    },
    objectMoved() {
      this.saveEnabled = true;
    },
    showImport() {
      this.importData.selectedObjects = [];
      this.importData.driveObjects = [];
      this.importData.googleDriveUrl = '';
      this.importData.showTable = false;
      if (process.env.VUE_APP_MODE === 'Development') {
        this.importData.googleDriveUrl = 'https://drive.google.com/drive/folders/1AQMk6Ml83LYt7B8Hr05nfxFknRipiUR2';
      }
      this.showDialog = true;
    },
    async fetchDriveData() {
      try {
        this.importData.driveObjects = await hierarchyService.getExternalDriveContent(this.importData.googleDriveUrl, this.$props.webconUser);
        this.importData.showTable = true;
        this.importData.showError = false;
      } catch (error) {
        this.importData.showError = true;
      }
    },
    cancelImport() {
      this.importData.selectedObjects = [];
      this.importData.driveObjects = [];
      this.importData.googleDriveUrl = '';
      this.importData.showTable = false;
      this.showDialog = false;
    },
    async importFiles() {
      if (await this.$refs.confirm.showDialog(
        'Confirm Import',
        'This will move all files from the current Drive folder into Curriculum Hub.  Are you sure  you wish to continue?',
        {
            width: '50%',
            confirmButtonTxt: "Yes, I'm sure",
            cancelButtonTxt: "No, I've chagned my mind",
        }
      )) {
        const importFiles = [];
        this.importData.selectedObjects.forEach((file) => {
          importFiles.push(file.id);
        });
        await hierarchyService.importExternalFiles(
          this.selectedNode.guid,
          importFiles,
          this.$props.webconUser)
          .then(() => {
            this.$store.dispatch('reloadHierarchy');
            this.$store.dispatch('getCurriculumHierarchy',
              this.$store.getters.CurriculumGuid,
              this.$props.webconUser);
          });
        this.showDialog = false;
      }
    },
    getIcon(item) {
      return this.parseFileType(item.type);
    }
  },
}
</script>

<style scoped>
.drag-icon {
  width: 20px;
}
.ext-table-row {

}
</style>