<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {version} from '../package.json';

export default {
  name: 'App',
  
  components: {
  },
  data: () => ({
    //
  }),
  mounted() {
    console.log(`%cPublishing UI Version ${version}`, 'color: blue;');
  },
};
</script>

<style scoped>
.v-application {
  font-family: Verdana;
  font-size: 0.9rem;
}
</style>