<template>
  <div>
    <v-form>
      <v-row>
        <v-col>
          <v-card elevation="1">
            <v-card-title>{{selectedNode.type.toLowerCase() === 'folder' ? 'Folder' : 'Object'}} Details</v-card-title>
            <v-card-text>
              <span class="field-label">Object Name:</span><span>{{selectedNode.name}}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!selectedNode.publishable">
        <v-col>
          <v-card elevation="1">
            <v-card-title>Document is Unpublishable</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    This object cannot be published at this time.  This can be due to several reasons, but most commonly, this is due to the object
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="field-label urgent">Unpublishable</span>
                    <span class="">{{selectedNode.unPublishableReason}}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              height="25">
            </v-progress-linear>
            <v-card-actions v-if="!loading && (!attemptTried || attemptFailed)">
              <v-spacer></v-spacer>
              <v-btn text color="green" @click="attemptFix">Attempt Fix</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card elevation="1">
            <v-card-title>Publish Details</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <span class="field-label">Default Publish Type:</span>
                    <span class="treeIcon" :class="parseFileType(defaultPublishType)"></span>
                    <span>{{defaultPublishType}}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select :items="publishTypes(selectedNode.type)"
                      label="Publish Type (Override)"
                      v-model="selectedNode.publishType"
                      :key="selectedNode.guid"
                      item-text="name"
                      item-value="publishType">
                      <template v-slot:item="{ item }">
                        <div class="treeIcon" :class="parseFileType(item.publishType)"></div>
                        <span class="v-select-item-text">{{item.name}}</span>
                      </template>
                      <template v-slot:selection="{ item }">
                        <div class="treeIcon" :class="parseFileType(item.publishType)"></div>
                        <span class="v-select-item-text">{{item.name}}</span>
                      </template>
                    </v-select>
                    <span style="cursor: pointer"
                      @click="selectedNode.publishType = null"
                      v-if="selectedNode.publishType">
                      <v-icon>mdi-close-circle-outline</v-icon>Clear Override
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  import fileTypeParser from '../mixins/fileTypeParser.mixin';
  import publishService from '../services/publish.service';

  export default {
    mixins: [fileTypeParser],
    props: {
      selectedNode: {
        type: Object,
      },
      curriculumGuid: {
        type: String,
      },
      webconUser: {
        type: String
      }
    },
    watch: {
      selectedNode(newValue, oldValue) {
        if (newValue != oldValue) {
          this.loading = false;
          this.attemptTried = false;
          this.attemptFailed = false;
        }
      }
    },
    data() {
      return {
        loading: false,
        attemptTried: false,
        attemptFailed: false,
      }
    },
    mounted() {
      this.$store.dispatch('getCurriculumSummary', this.$props.curriculumGuid);
    },
    computed: {
      defaultPublishType() {
        const typeOptions = this.$store.getters.CurriculumSummary.filter((obj) => obj.objectType === this.selectedNode.type)[0];
        if (!typeOptions || !typeOptions.publishType) {
          return 'Not Set';
        }
        return typeOptions.publishType;
      },
    },
    methods: {
      async attemptFix() {
        this.loading = true;
        this.attemptTried = true;
        const result = await publishService.attemptFix(this.$props.selectedNode.id, this.$props.webconUser);
        if (result) {
          this.$props.selectedNode.publishable = true;
          this.$store.dispatch('updateCurriculumSummary');
        } else {
          this.attemptFailed = true;
        }
        this.loading = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
.v-select-item-text {
  padding-left: 3px;
}
.field-label {
  font-weight: bold;
  padding-right: 5px;
}
.treeIcon {
  margin-right: 3px;
}
</style>