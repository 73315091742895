//import data from '../data/hierarchy-test.data';
import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_HIERARCHY_URL}`;
const getUrl = `${rootUrl}/hierarchy`;
const getTree = `${getUrl}/treeview`
const getCurriculum = `${rootUrl}/curriculum`;
const getPublishTypeMap = `${rootUrl}/dict/publish-type-map`;
const getDriveContentUrl = `${rootUrl}/CurriculumImport/external-folder/get-files`;
const importDriveContentUrl = `${rootUrl}/CurriculumImport/import-drive-files`;
const correlationUrl = `${rootUrl}/correlation`;

const objectCdfOverrides = `${rootUrl}/hierarchies`;

const author_key = `${process.env.VUE_APP_HIERARCHY_ID}`;
const author_secret = `${process.env.VUE_APP_HIERARCHY_SECRET}`;

let token = '';
let tokenExpire = '';

async function doAuth() {
  if (token && tokenExpire > new Date()) {
    return token;
  }
  const url = `${rootUrl}/connect/token`;
  const response = await axios(
    {
      url: url,
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      data: `grant_type=client_credentials&scope=api&client_id=${author_key}&client_secret=${author_secret}`,
    }
  );
  token = response.data.access_token;
  const dt = new Date();
  tokenExpire = new Date(dt.setMinutes(dt.getMinutes() + (response.data.expires_in / 60) - 5));
}
export default {
  async getCurriculumHierarchy(curriculumGuid, webconUser) {
    await doAuth();
    console.log('Fetching Curriculum Hierarchy for GUID: ' + curriculumGuid);

    const url = `${getTree}/${curriculumGuid}`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
      }
    );
    return response.data;
  },
  async getHierarchyCDFs(curriculumGuid, hierarchyGuid) {
    await doAuth();
    console.log(`Fetching Hierarchy CDFs for GUIDs(C/H): ${curriculumGuid}/${hierarchyGuid}`);

    const url = `${getUrl}/${curriculumGuid}/${hierarchyGuid}/CustomDataFields`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async getHierarchyObjects(curriculumGuid, hierarchyGuid) {
    await doAuth();
    console.log(`Fetching Hierarchy Objects for GUIDs(C/H): ${curriculumGuid}/${hierarchyGuid}`);

    const url = `${getUrl}/${curriculumGuid}/${hierarchyGuid}`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async saveHierarchyObjectOrder(curriculumGuid, hierarchyGuid, objectOrder, webconUser) {
    await doAuth();
    console.log(`Saving Hierarchy Object Order for GUIDs(C/H): ${curriculumGuid}/${hierarchyGuid}`);

    const url = `${getUrl}/${curriculumGuid}/${hierarchyGuid}`;
    const response = await axios(
      {
        url: url,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify(objectOrder),
      }
    );
    return response.data;
  },
  async getCurriculumObjectSummary(curriculumGuid) {
    await doAuth();
    console.log(`Fetching Curriculum Object Summary for GUID: ${curriculumGuid}`);

    const url = `${getCurriculum}/${curriculumGuid}/objectsSummary`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async getPublishTypeMap() {
    await doAuth();
    console.log(`Fetching Publish Type Map`);

    const url = `${getPublishTypeMap}`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async updateCDF(curriculumGuid, hierarchyGuid, cdf, webconUser) {
    await doAuth();

    const url = `${getUrl}/${curriculumGuid}/${hierarchyGuid}/CustomDataFields`;
    const response = await axios(
      {
        url: url,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify(cdf),
      }
    );
    console.log(response);
    return response.data;
  },
  async addCDF(curriculumGuid, hierarchyGuid, cdf, webconUser) {
    await doAuth();

    const url = `${getUrl}/${curriculumGuid}/${hierarchyGuid}/CustomDataFields`;
    const response = await axios(
      {
        url: url,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify(cdf),
      }
    );
    console.log(response);
    return response.data;
  },
  async deleteCDF(curriculumGuid, hierarchyGuid, cdf, webconUser) {
    await doAuth();

    const url = `${getUrl}/${curriculumGuid}/${hierarchyGuid}/CustomDataFields`;
    const response = await axios(
      {
        url: url,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify(cdf),
      }
    );
    console.log(response);
    return response.data;
  },
  async getExternalDriveContent(driveUrl, webconUser) {
    await doAuth();

    const url = `${getDriveContentUrl}`;
    const response = await axios(
      {
        url: url,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify({
          folderUrl: driveUrl
        }),
      }
    );

    return response.data;
  },
  async importExternalFiles(hierarchyGuid, files, webconUser) {
    await doAuth();

    const url = `${importDriveContentUrl}`;
    const response = await axios(
      {
        url: url,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify({
          hierarchyGuid: hierarchyGuid,
          files: files,
          moveFiles: true,
        }),
      }
    );

    return response.data;
  },
  async getCurriculumStandardSets(curriculumGuid) {
    await doAuth();

    const url = `${correlationUrl}/curriculum/${curriculumGuid}/applied`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getHierarchyStandardSets(hierarchyGuid) {
    await doAuth();

    const url = `${correlationUrl}/hierarchy/${hierarchyGuid}/applied`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getObjectStandardSets(objectGuid) {
    await doAuth();

    const url = `${correlationUrl}/object/${objectGuid}/applied`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getCurriculumAvailableStandardSets(curriculumGuid) {
    await doAuth();

    const url = `${correlationUrl}/curriculum/${curriculumGuid}/available`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getHierarchyAvailableStandardSets(hierarchyGuid) {
    await doAuth();

    const url = `${correlationUrl}/hierarchy/${hierarchyGuid}/available`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getObjectAvailableStandardSets(objectGuid) {
    await doAuth();

    const url = `${correlationUrl}/object/${objectGuid}/available`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getCurriculumAppliedStandardSets(curriculumGuid) {
    await doAuth();

    const url = `${correlationUrl}/curriculum/${curriculumGuid}/applied`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getHierarchyAppliedStandardSets(hierarchyGuid) {
    await doAuth();

    const url = `${correlationUrl}/hierarchy/${hierarchyGuid}/applied`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getObjectAppliedStandardSets(objectGuid) {
    await doAuth();

    const url = `${correlationUrl}/object/${objectGuid}/applied`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getStandards() {
    await doAuth();

    const url = `${correlationUrl}/standards`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async getStandardDetails() {
    await doAuth();

    const url = `${correlationUrl}/standards/details`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  },
  async createStandard(payload, webconUser) {
    await doAuth();

    const url = `${correlationUrl}`;
    const response = await axios(
      {
        url: url,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify(payload),
      }
    ).catch(err => {
      console.log('Error %o', err);
    });

    return response.data;
  },
  async removeStandard(correlationId, webconUser) {
    await doAuth();

    const url = `${correlationUrl}/${correlationId}/rolldown`;
    await axios(
      {
        url: url,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
      }
    );
  },
  async rollDownStandards(hierarchyGuid, webconUser) {
    await doAuth();

    const url = `${correlationUrl}/hierarchy/${hierarchyGuid}/rolldown`;
    await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
      }
    );
  },
  async getObjectCDFOverrides(hierarchyGuid, objectId, webconUser) {
    await doAuth();

    const url = `${objectCdfOverrides}/${hierarchyGuid}/objects/${objectId}/overrides`;
    const response = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
      }
    );

    return response.data;
  },
  async setObjectCDFOverrides(hierarchyGuid, objectId, cdf, webconUser) {
    await doAuth();

    const dataObj = {
      customFieldDataId: cdf.id,
      valueKey: cdf.valueKey,
      value: cdf.value,
      hierarchyGuid: hierarchyGuid,
      objectId: objectId,
    };

    if (cdf.overrideId && cdf.overrideId > 0) {
      dataObj.id = cdf.overrideId;
    }
    const url = `${objectCdfOverrides}/${hierarchyGuid}/objects/${objectId}/overrides`;
    const response = await axios(
      {
        url: url,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify(dataObj),
      }
    );

    return response.data;
  },
  async getObjectCDFOverride(hierarchyGuid, objectId, cdfId, webconUser) {
    await doAuth();

    const url = `${objectCdfOverrides}/${hierarchyGuid}/objects/${objectId}/overrides/${cdfId}`;
    await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
      }
    );
  },
  async removeObjectCDFOverride(hierarchyGuid, objectId, cdfId, webconUser) {
    await doAuth();

    const url = `${objectCdfOverrides}/${hierarchyGuid}/objects/${objectId}/overrides/${cdfId}`;
    await axios(
      {
        url: url,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
      }
    );
  }
}