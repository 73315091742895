import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_PUBLISH_URL}`;

const author_key = `${process.env.VUE_APP_PUBLISH_ID}`;
const author_secret = `${process.env.VUE_APP_PUBLISH_SECRET}`;

let token = '';
let tokenExpire = '';

async function doAuth() {
  if (token && tokenExpire > new Date()) {
    return token;
  }
  const url = `${rootUrl}/connect/token`;
  const response = await axios(
    {
      url: url,
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      data: `grant_type=client_credentials&scope=api&client_id=${author_key}&client_secret=${author_secret}`,
    }
  );
  token = response.data.access_token;
  const dt = new Date();
  tokenExpire = new Date(dt.setMinutes(dt.getMinutes() + (response.data.expires_in / 60) - 5));
}

function getPublishTypeDefault(objectType, overrideType, publishTypes) {
  if (overrideType && overrideType.length > 0) {
    return overrideType;
  }
  const typeOptions = publishTypes.filter((obj) => obj.objectType === objectType)[0];
  if (!typeOptions || !typeOptions.publishType) {
    return 'NoChange';
  }
  return (typeOptions.publishType && typeOptions.publishType.length > 0) ? typeOptions.publishType : 'NoChange';
}

export default {
  async publishCurriculum(curriculumGuid, publishOptions, checkedNodes, publishTypeDefaults, webconUser) {
    await doAuth();

    const payload = {
      curriculumGUID: curriculumGuid,
      publishTitle: publishOptions.title,
      publishDesc: publishOptions.description,
      publishType: publishOptions.outputType,
      pathType: publishOptions.pathType,
      language: publishOptions.language,
      instanceId: publishOptions.instanceId,
      standardSetGUID: publishOptions.selectedStandard.docIdentifier,
      DefaultGradeRange: `${publishOptions.lowerGradeRange} to ${publishOptions.upperGradeRange}`,
      itemList: [],
      publishMimeTypeMappings: [],
    };

    checkedNodes.forEach((node) => {
      if (node.type.toUpperCase() !== 'FOLDER') {
        payload.itemList.push({
          objectId: node.id,
          publishMimeType: getPublishTypeDefault(node.type, node.publishType, publishTypeDefaults),
        });
      }
    });

    publishTypeDefaults.forEach((type) => {
      payload.publishMimeTypeMappings.push({
        sourceMimeType: type.objectType,
        destinationMimeType: getPublishTypeDefault(type.objectType, '', publishTypeDefaults),
      });
    });

    const url = `${rootUrl}/Publish/PublishSelectedNodes`;
    const response = await axios({
      url: url,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
        'webcon-user': webconUser,
      },
      data: JSON.stringify(payload),
    });

    return response.data;
  },
  async attemptFix(objectId, webconUser) {
    await doAuth();

    const url = `${rootUrl}/Publish/unpublishable/${objectId}/override`;
    const response = await axios({
      url: url,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
        'webcon-user': webconUser,
      }
    });

    return response.data;
  },
  async migrateComplete(curriculumGuid) {
    await doAuth();

    const url = `${rootUrl}/hierarchy/${curriculumGuid}/migrate-sync-complete`;
    const response = await axios({
      url: url,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    });

    return response.data;   
  }
}