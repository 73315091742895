<template>
  <div>
    <v-card elevation="1">
      <v-card-title>
        <v-row>
          <v-col cols="5">
            <span text @click="toggleView"><v-icon>{{toggleIcon}}</v-icon></span>
            <span @click="toggleView">Custom Data Fields</span>
          </v-col>
          <v-col cols="2" offset="5" v-if="!isCollapsed && selectedNode.type === 'Folder'">
            <v-btn text @click="showNewDialog"><v-icon color="green">mdi-plus-circle-outline</v-icon>Add New</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        v-if="!isCollapsed"
        :headers="tableHeaders"
        :items="dataFields"
        :show-expand="false"
        :show-select="false"
        :show-group-by="false"
        :disable-pagination="true"
        :hide-default-footer="true"
        dense
        :loading="tableLoading">
        <template v-slot:loading>
          Fetching Custom Field Data...
        </template>
        <template v-slot:progress>
          <v-progress-linear
            v-if="tableLoading"
            indeterminate
            rounded>
          </v-progress-linear>
        </template>
        <template v-slot:item.id="{ item }">
          <v-btn text @click="showEditDialog(item)" class="btn-action">
            <v-icon color="primary lighten-2">mdi-pencil</v-icon>
          </v-btn>
          <v-btn text @click="processDelete(item)" class="btn-action">
            <v-icon color="red lighten-2">mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.value="{ item } ">
          <div v-html="item.value"></div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="showDialog"
      :retain-focus="false"
      persistent
      width="60%">
      <v-card rounded shaped>
        <v-card-title>Custom Field Data</v-card-title>
        <v-card-text>
          <v-form ref="cdfEdit">
            <v-text-field label="Field Name" v-model="dataFieldItem.fieldName"></v-text-field>
            <v-select label="Languages" v-model="dataFieldItem.language" :items="languages" :readonly="isReadOnly" :rules="[dataFieldItem.rules.required]"></v-select>
            <v-text-field label="Match" v-model="dataFieldItem.match" :readonly="isReadOnly"></v-text-field>
            <ckeditor :editor="editor" v-model="dataFieldItem.value" :config="editorConfig"></ckeditor>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="saveDialog">
            Save
          </v-btn>
          <v-btn text color="red lighten-2" @click="hideDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDeleteConfirm"
      persistent
      width="40%">
      <v-card rounded shaped>
        <v-card-title>Confirm Delete</v-card-title>
        <v-card-text>
          Are you sure you wish to delete this Custom Field Data item?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red lighten-2" @click="deleteItem">
            Delete
          </v-btn>
          <v-btn text color="primary" @click="cancelDelete">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confDialog"></confirm-dialog>
  </div>
</template>

<script>
import hierarchyService from '../services/hierarchy.service';
import collapsible from '../mixins/collapsible.mixin';
import CustomEditor from 'ckeditor5-custom-build/build/ckeditor';
import ConfirmDialog from './ConfirmDialog.vue';

export default {
  mixins: [collapsible],
  components: { ConfirmDialog },
  props: {
    selectedNode: {
      type: Object,
    },
    curriculumGuid: {
      type: String,
    },
    hierarchyGuid: {
      type: String,
    },
    webconUser: {
      type: String,
    },
  },
  data() {
    return {
      showDialog: false,
      tableLoading: true,
      dataFields: [],
      selectedItem: {},
      showDeleteConfirm: false,
      editor: CustomEditor,
      editorConfig: {
        toolbar: {
          items: [ 'alignment', 'bold', 'italic', 'underline', 'removeformat',
            '|', 'numberedList', 'bulletedList', 
            '|', 'indent', 'outdent',
            '|', 'undo', 'redo',
            '|', 'link', 'sourceediting',
          ],
          shouldNotGroupWhenFull: true,
        },
        link: {
            defaultProtocol: "https://",
            addTargetToExternalLinks: true,
            decorators: {
              openInNewTab: {
                mode: 'manual',
                label: 'Open in New Tab',
                attributes: {
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
              },
            },
          },
      },
      dataFieldItem: {
        fieldName: '',
        language: '',
        match: '*',
        value: '',
        mode: 'new',
        rules: {
          required: value => !!value || 'Required.',
        }
      },
      languages: [
        { text: 'English', value: 'en' },
        { text: 'Spanish', value: 'es' },
      ],
      tableHeaders: [
        { text: '', align: 'start', sortable: false, value: 'id' },
        { text: 'Name', align: 'start', sortable: false, value: 'valueKey' },
        { text: 'Language', align: 'start', sortable: false, value: 'language' },
        { text: 'Match Key', align: 'start', sortable: false, value: 'match' },
        { text: 'Value', align: 'start', sortable: false, value: 'value' },
      ],
    }
  },
  computed: {
    isReadOnly() {
      return this.selectedNode.type !== 'Folder' && this.dataFieldItem.mode === 'edit'; 
    }
  },
  watch: {
    curriculumGuid(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.tableLoading = true;
        this.dataFields = [];
        this.fetchData();
      }        
    },
    hierarchyGuid(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.tableLoading = true;
        this.dataFields = [];
        this.fetchData();
      }
    },
    selectedNode(newValue, oldValue) {
      if (newValue.id !== oldValue.id) {
        this.tableLoading = true;
        this.dataFields = [];
        this.fetchData();
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.dataFields = await hierarchyService.getHierarchyCDFs(this.$props.curriculumGuid, this.$props.hierarchyGuid);

      let cdf;

      if (this.$props.selectedNode.type.toLowerCase() != 'folder') {
        const overrides = await hierarchyService.getObjectCDFOverrides(this.$props.selectedNode.guid, this.$props.selectedNode.id);
        overrides.forEach(override => {
          cdf = this.dataFields.filter(item => {
            return item.valueKey === override.valueKey;
          })[0];
          if (cdf) {
            // Replace set Overrides
            cdf.orgValue = cdf.value;
            cdf.value = override.value;
            cdf.overrideId = override.id;
          } else {
            this.dataFields.push({
              id: override.id,
              overrideId: override.id,
              valueKey: override.valueKey,
              value: override.value,
            });
          }
        })
      }
      this.tableLoading = false;
    },
    showNewDialog() {
      this.selectedItem = {};
      this.dataFieldItem.mode = 'new';

      this.showDialog = true;
    },
    showEditDialog(item) {
      this.selectedItem = item;

      this.dataFieldItem.fieldName = item.valueKey;
      this.dataFieldItem.language = item.language;
      this.dataFieldItem.match = item.match;
      this.dataFieldItem.value = item.value;
      this.dataFieldItem.mode = 'edit';

      this.showDialog = true;
    },
    clearForm() {
      this.dataFieldItem.fieldName = '';
      this.dataFieldItem.language = '';
      this.dataFieldItem.match = '*';
      this.dataFieldItem.value = '';

      this.selectedItem = {};
      
      this.$refs.cdfEdit.resetValidation();
    },
    hideDialog() {
      this.clearForm();

      this.showDialog = false;

      console.log(this.editor.ui);
    },
    async saveDialog() {
      if (!this.$refs.cdfEdit.validate()) {
        return;
      }
      if (this.selectedItem && this.selectedItem.id) {
        // Editing CDF or CDFO
        this.selectedItem.valueKey = this.dataFieldItem.fieldName;
        this.selectedItem.language = this.dataFieldItem.language;
        this.selectedItem.match = this.dataFieldItem.match;
        this.selectedItem.orgValue = this.selectedItem.value;
        this.selectedItem.value = this.dataFieldItem.value;
        
        if (this.selectedItem.overrideId || this.$props.selectedNode.type.toLowerCase() !== 'folder') {
          // Editing CDFO
          const cdfo = await hierarchyService.setObjectCDFOverrides(this.$props.selectedNode.guid, this.$props.selectedNode.id, this.selectedItem, this.$props.webconUser);
          this.selectedItem.overrideId = cdfo.id;
          this.selectedItem.overrideValue = cdfo.keyValue;
        } else {
          // Editing CDF
          await hierarchyService.updateCDF(this.$props.curriculumGuid, this.$props.hierarchyGuid, this.selectedItem, this.$props.webconUser);
          this.selectedItem.orgValue = null;
        }
      } else {
        // Adding CDF or CDFO
        // Validate Entries
        const existingCDF = this.dataFields.filter(item => {
          return item.valueKey === this.dataFieldItem.fieldName && item.language === this.dataFieldItem.language;
        });
        if (existingCDF && existingCDF.length === 1) {
          // Stop them from adding
          await this.$refs.confDialog.showDialog('Already Exists', 'The Field Name and Language you entered already exist.  Please edit the existing value.', {
            width: '50%',
            confirmButtonTxt: 'Ok',
            cancelButtonTxt: 'Cancel',
          });
          this.clearForm()

          this.showDialog = false;
          return;
        }
        let newObject;
        if (this.$props.selectedNode.type.toLowerCase() === 'folder') {
          newObject = await hierarchyService.addCDF(
          this.$props.curriculumGuid,
          this.$props.hierarchyGuid,
          {
            valueKey: this.dataFieldItem.fieldName,
            value: this.dataFieldItem.value,
            match: this.dataFieldItem.match,
            language: this.dataFieldItem.language,
          },
          this.$props.webconUser);
        } else {
          newObject = await hierarchyService.setObjectCDFOverrides(
            this.$props.selectedNode.guid,
            this.$props.selectedNode.id,
            {
              hierarchyGuid: this.$props.selectedNode.guid,
              objectId: this.$props.selectedNode.id,
              valueKey: this.dataFieldItem.fieldName,
              value: this.dataFieldItem.value,
              match: this.dataFieldItem.match,
              language: this.dataFieldItem.language,
            },
            this.$props.webconUser);

            newObject.overrideId = newObject.id;
            newObject.overrideValue = newObject.keyValue;
        }

        // Handle existing CDFs Vs. new CDFs
        this.dataFields.push(newObject);
      }
      this.clearForm()

      this.showDialog = false;
    },
    processDelete(cdf) {
      if (this.$props.selectedNode.type.toLowerCase() === 'folder' ||
        !cdf.overrideId || cdf.overrideId <= 0) {
        // Deleting a CDF
        this.$refs.confDialog.showDialog(
          'Deleting Common Custom Data Field',
          'If you delete this Custom Data Field it might have impact on other areas of this curriculum.  Are you sure you wish to do this?',
          {
            width: '50%',
            confirmButtonTxt: 'Yes, I\'m sure',
            cancelButtonTxt: 'No',
          }).then(result => {
            if (result) {
              this.selectedItem = cdf;
              this.deleteItem();
            }
          });
      } else {
        // Deleting a CDFO
        this.$refs.confDialog.showDialog(
          'Deleting Custom Data Field Override',
          'You are about to delete an override value for a custom data field.  Are you sure you wish to do this?',
          {
            width: '50%',
            confirmButtonTxt: 'Yes, Delete Override',
            cancelButtonTxt: 'No', 
          }).then (result => {
            if (result) {
              this.selectedItem = cdf;
              this.deleteItem();
            }
          });
      }
    },
    async deleteItem() {
      if (this.selectedItem && this.selectedItem.id && this.selectedItem.overrideId) {
        /// Remove CDFO
        await hierarchyService.removeObjectCDFOverride(this.$props.hierarchyGuid, this.selectedItem.id, this.selectedItem.overrideId, this.$props.webconUser);
        this.selectedItem.value = this.selectedItem.orgValue;
      } else {
        /// Remove CDF
        await hierarchyService.deleteCDF(this.$props.curriculumGuid, this.$props.hierarchyGuid, this.selectedItem, this.$props.webconUser)
          .then(() => {
            const selectedIndex = this.dataFields.indexOf(this.selectedItem, 0);
            this.dataFields.splice(selectedIndex, 1);
          });
      }
      this.selectedItem = {};
      this.showDeleteConfirm = false;
    },
    cancelDelete() {
      this.selectedItem = {};
      this.showDeleteConfirm = false;
    },
  },
}
</script>

<style>
.ck.ck-balloon-panel {
  z-index: 1400 !important;
}
.ck.ck-content {
  min-height: 400px;
  height: 400px;
}
</style>

<style scoped>
.btn-action {
  min-width: 16px !important;
  width: 16px !important;
  padding-left: 1px;
  padding-right: 1px;
}
</style>